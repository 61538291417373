import React, {useEffect, useState} from "react";
import axios from "axios";

const AdminAllUsers = () => {

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    useEffect(() => {
        getData()
    }, []);

    const [data, setData] = useState([])

    const getData = () => {
        api.get('/getall.php').then(
            (response) => {
                if (response.data.error == null) {
                    console.log(response.data)
                    setData(response.data)
                }
            }
        )
    }

    const generateTable = (data: any[]) => {
        if (data !== []){
            console.log(typeof data)
            return data.map((user: any) => {
                return (
                    <tr>
                        <td>{user.companyName}</td>
                        <td>{user.companyType}</td>
                        <td>{user.companyContry}</td>
                        <td>{user.logins}</td>
                        <td>{user.last_login_date}</td>
                        <td>{user.companyAdress}</td>
                        <td>{user.companyMail}</td>
                        <td>{user.companyUNP}</td>
                        <td>{user.companyINN}</td>
                        <td>{user.companyKPP}</td>
                        <td>{user.companyBankName}</td>
                        <td>{user.companyBankAccount}</td>
                        <td>{user.companySWIFT}</td>
                        <td>{user.companyBIK}</td>
                        <td>{user.companyPhone}</td>
                        <td>{user.companyPassport}</td>
                        <td>{user.type}</td>
                        <td>{user.dealerDiscounts}</td>
                        <td>{user.discount}</td>
                        <td>{user.autDiscount}</td>
                        <td>{user.whoIsDealer}</td>
                    </tr>
                )
            })
        }
    }

    return(
        (data) && (
            <table>
                <tr>
                    <th>Компания</th>
                    <th>Тип компании</th>
                    <th>Страна компании</th>
                    <th>Кол-во входов</th>
                    <th>Дата последнего входа</th>
                    <th>Адресс</th>
                    <th>Mail</th>
                    <th>УНП</th>
                    <th>ИНН</th>
                    <th>КПП</th>
                    <th>Название банка</th>
                    <th>Номер счета</th>
                    <th>Swift</th>
                    <th>BIK</th>
                    <th>Номер телефона</th>
                    <th>Паспорт</th>
                    <th>Роль</th>
                    <th>Дилерская скидка</th>
                    <th>Скидка на конструкцию</th>
                    <th>Скидка на автоматику</th>
                    <th>Приписанный диллер</th>
                </tr>
                {generateTable(data)}
            </table>
        )
    )
}

export default AdminAllUsers