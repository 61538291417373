import axios from "axios";
import {DefaultValues} from "react-hook-form";
import {FormValues} from "../types/types";

const api = axios.create({
    baseURL: 'https://wholesale.markiz.by/'
})

export default class SaveSystemService {

    //Get user's saved orders
    async getSavedOrders(email: string){
        console.log('Email', email)
        let res = await api.post('/getSavedOrders.php', {email})
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

    //Saves new order
    async saveOrder(email: string, data: DefaultValues<FormValues>, status: string, price?: number){

        let request = {
            formData: data,
            email: email,
            status: status,
            price: price
        }
        let res = await api.post('/saveOrder.php', {...request})
        if (res.data !== 'Ошибка, заказ не был сохранен.') {
            console.log(res)
            return true
        } else {
            console.log(res)
            return false
        }
    }

    //Delete saved order
    async deleteOrder(id:number){

        let request = {
            id: id
        }

        let res = await api.post('/deleteSavedOrder.php', {...request})
        if (res.data.success) {
            console.log(res)
            return true
        } else {
            console.log(res)
            return false
        }
    }

    //Get user's previous orders
    async getArticles(){
        let res = await api.post('/getArticles.php')
        if (res.data.error == null) {
            return res.data
        } else {
            console.log('Error popup')
            return false
        }
    }

}