import React, {Fragment, useEffect, useState} from "react";
import SaveSystemService from "../../../services/save-system-service";
import {create} from "domain";
import {Link} from "react-router-dom";

type TableProps = {
    listOfOrders: any,
    callPopup: (message: string, type: string) => void
}

export const Table = ({listOfOrders, callPopup}:TableProps) => {

    const initialState = listOfOrders ? listOfOrders : false
    const [ordersList, setOrdersList] = useState(initialState)

    useEffect(()=>{
        setOrdersList(listOfOrders)
    }, [listOfOrders])

    const orderInfo = (data: any) => {
        if (data.items?.length !== undefined) {
            return data.items.map((item:any) => {
                return (
                    <div>
                        {
                            item.model && ('Модель: ' + item.model)
                        }
                        {
                            item.width && (<div>{'Размеры: ' + item.width + ' X ' + item.height}</div>)
                        }
                        {
                            item.volan && (<div>{'Волан: ' + item.volan}</div>)
                        }
                        {
                            item.mountType && (<div>{'Вид монтажа/Тип крепления: ' + item.mountType}</div>)
                        }
                        {
                            item.cap && (<div>{'Верхняя укрывающая крышка: ' + item.cap}</div>)
                        }
                        {
                            item.ads && (<div>{'Размещение рекламы: ' + item.ads}</div>)
                        }
                        {
                            item.server && (<div>{'Мини сервер: ' + item.server}</div>)
                        }
                        {
                            item.led && (<div>{'LED: ' + item.led}</div>)
                        }
                        {
                            item.color && (<div>{'Цвет профилей: ' + item.color}</div>)
                        }
                        {
                            item.factory && (<div>{'Производитель тента: ' + item.factory}</div>)
                        }
                        {
                            item.article && (<div>{'Артикул тента: ' + item.article}</div>)
                        }
                        {
                            item.article2 && (<div>{'Сторона ткани в изделии: ' + item.article2}</div>)
                        }
                        {
                            item.control && (<div>{'Управление: ' + item.control}</div>)
                        }
                        {
                            item.controlSide && ('Сторона управления: ' + item.controlSide)
                        }
                        {
                            item.controlsFactory && ('Производитель привода: ' + item.controlsFactory)
                        }
                        {
                            item.controlAcceptor && ('Вид привода: ' + item.controlAcceptor)
                        }
                        {
                            item.automaticFactory && ('Производитель автоматики: ' + item.automaticFactory)
                        }
                        {
                            item.swb && ('Выключатель для накладного монтажа: ' + item.swb)
                        }
                        {
                            item.pilotQuantity && ('Пульты: ' + item.pilotQuantity + 'шт. Тип: ' + item.pilotType)
                        }
                        {
                            item.automaticAddition && ('Дополнительная автоматика: ' + item.automaticAddition)
                        }
                        {
                            item.zip && ('Длина молни (метры): ' + item.zip)
                        }
                        {
                            item.belts && ('Количество ремешков: ' + item.belts + '.')
                        }
                        <br/>
                        {
                            item.notes && (`Примечание: ` + item.notes)
                        }
                    </div>
                )
            })
        } else {
            return(
                <div>
                    Нет информации о заказе
                </div>
            )
        }
    }

    const modifyDate = (date: string) => {
        console.log(date)
        const tmp = new Date(date.replace(' ', 'T'))
        return tmp.getDate() + '-' + (tmp.getMonth() + 1) + '-' + tmp.getFullYear()
    }

    const deleteOrder = (index: number, id: number) => {
        const saveSystem = new SaveSystemService()

        saveSystem.deleteOrder(id).then((res)=>{
            if(res) {
                callPopup('Заказ успешно удален!', 'success')
                if(ordersList) {
                    setOrdersList(ordersList.filter((order: any) => order !== ordersList[index]))
                }
            } else {
                callPopup('Заказ не был удален!', 'error')
            }
        })
    }

    return (
        <div className="py-16 mx-auto">
            <div className="sm:mx-auto">
                <table className='table-auto'>
                    <thead>
                        <tr>
                            <th className='border border-black'>Номер заказа:</th>
                            <th className='border border-black'>Дата заказа:</th>
                            <th className='border border-black'>Заказ:</th>
                            <th className='border border-black'>Состояние заказа:</th>
                            <th className='border border-black'>Стоимость:</th>
                            <th className='border border-black'>Примерная дата выхода из производства:</th>
                            <th className='border border-black'>Действия:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ordersList ?
                                ordersList.map((order: any, index: number) => {
                                    console.log(order)
                                    return(
                                        <tr key={index}>
                                            <th className='bg-gray-300 border border-black'>{order.order_number ? order.order_number : order.id}</th>
                                            <th className='border border-black'>{modifyDate(order.create_date)}</th>
                                            <th className='text-left bg-gray-300 border border-black'>
                                                <div className='max-h-40 overflow-y-scroll'>
                                                    {orderInfo(order.data)}
                                                </div>
                                            </th>
                                            <th className='border border-black'>{order.status}</th>
                                            <th className='border border-black'>{order.price}</th>
                                            <th className='bg-gray-300 border border-black'>{order.production_date === '0000-00-00' ? '-' : modifyDate(order.production_date)}</th>
                                            <th className='justify-center border border-black'>
                                                <div className='grid justify-items-center'>
                                                    {
                                                        (order.status !== 'Заказан') && (
                                                            <Link to={{ pathname: '/order-form', state: { savedOrder: order.data, mode: 'normal'} }} className='block mb-2 bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-3 px-4 rounded'>
                                                                Заказать
                                                            </Link>
                                                        )
                                                    }
                                                    {
                                                        (order.status === 'Сохранён') && (
                                                            <React.Fragment>
                                                                <button className={'block mb-2 bg-red-500 cursor-pointer hover:bg-red-700 text-white font-bold py-3 px-4 rounded'} onClick={() => {
                                                                    deleteOrder(index, order.id)
                                                                }}>Удалить</button>
                                                                <Link to={{ pathname: '/order-form', state: { savedOrder: order.data, mode: 'recalculate'} }} className='block mb-2 bg-yellow-400 cursor-pointer hover:bg-yellow-500 text-white font-bold py-3 px-4 rounded'>
                                                                    Расчитать предложение
                                                                </Link>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                    )
                                }) : false
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};