import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import axios from "axios";
import SaveSystemService from "../../../services/save-system-service";
import {watch} from "fs";

type props = {
    callPopup: (message: string, type: string) => void
}
type form = {
    data: { article: string }[],
    company: string
}

const AdminChangeArticle = ({callPopup}: props) => {

    const [amList, setAmList] = useState([])
    const [copacoList, setCopacoList] = useState([])
    const [dicksonList, setDicksonList] = useState([])
    const [activeList, setActiveList] = useState([])

    const { register, handleSubmit, reset, control, watch } = useForm<form>({
        mode: "onChange",
        shouldUnregister: true
    })
    const { fields, append, remove, update } = useFieldArray({
        name: "data",
        control
    })

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })
    const isFormVisible = () => {
        return copacoList.length > 0 && dicksonList.length > 0;
    }

    useEffect(() => {
        loadArticles()
    }, [activeList])

    const loadArticles = () => {
        if(activeList.length === 0){
            const SaveSystem = new SaveSystemService()
            SaveSystem.getArticles().then((art) => {
                if (art[0].company === 'COPACO'){
                    setCopacoList(art[0].data)
                    setDicksonList(art[1].data)
                } else {
                    setCopacoList(art[1].data)
                    setDicksonList(art[0].data)
                }
                setActiveList(copacoList)
                reset({
                    data: copacoList.map((article) => {
                        return {article: article}
                    })
                })
            })
        }
    }

    const setFromActiveList = (company: string) => {
        let list =[]
        if (company === 'COPACO'){
            list = copacoList
        } else {
            list = dicksonList
        }
        reset({
            company: company,
            data: list.map((article) => {
                return {article: article}
            })
        })
    }

    const onSubmit = (values: form) => {
        const {data, company} = values

        const request = {
            company: company,
            articles: data.map((item) => {
                return item.article
            })
        }

        api.post('/changeArticle.php', request).then((res) => {
            if (res.data === 'Готово') {
                callPopup('Артикулы успешно изменены!', 'success')
            } else {
                callPopup(`Артикулы ну были изменены! Ошибка: ${res.data}`, 'error')
            }
        })
    }

    return (
        <div>
            {(activeList.length > 0) &&
            (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-divs">
                        <label htmlFor="company">Выберите производителя тента:</label>
                        <select id="company" {...register("company", {required: "Обязательное поле"})} onChange={
                            (e) => {
                                if (e.target.value === 'COPACO'){
                                    setFromActiveList('COPACO')
                                } else {
                                    setFromActiveList('Dickson акриловые ткани')
                                }
                            }
                            }>
                            <option>COPACO</option>
                            <option>Dickson акриловые ткани</option>
                        </select>
                    </div>
                    <br/>
                    <ul>
                        {fields.map((item, index) => {
                            return (
                                <li className="flex flex-row mb-5" key={item.id}>
                                    <input className='w-48' {...register(`data.${index}.article`)} />
                                    <button className='ml-5 rounded w-24 bg-red-100 h-11' type="button" onClick={() => remove(index)}>
                                        Удалить
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                    <button className='ml-5 rounded w-24 bg-green-300 h-11' type="button" onClick={() => append({article: 'Новый артикль'})}>
                        Добавить
                    </button>
                    <div className="flex justify-center space-x-4">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit">Сохранить</button>
                    </div>
                </form>
            )}
        </div>
    )
}

export default AdminChangeArticle