import React, {useEffect} from "react";
import axios from "axios";
import {useForm} from "react-hook-form";
import {AddFormType, AlertsProps} from "../../../types/types";
import {Alerts} from "../notifications/alerts";

type props = {
    data?: AddFormType,
    func?: () => void,
    formType: string,
    callPopup: (message: string, type: string) => void
}

const AdminAddForm = (props: props) => {

    const { register, watch, handleSubmit, reset } = useForm<AddFormType>({
        shouldUnregister: true
    })

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    const mailStyle = props.formType === 'update'

    useEffect(() => {
        if(props.data){
            reset(props.data)
        }
    }, [reset]);

    const onSubmit = (data: AddFormType) => {
        console.log(data)

        const request = {
            companyName: data.companyName,
            orderNumber: data.regOrderNumber,
            companyType: data.type,
            companyContry: data.country,
            companyAdress: data.companyAdress,
            companyMail: data.companyMail,
            companyUNP: data.companyUNP,
            companyINN: data.companyINN,
            companyKPP: data.companyKPP,
            companyBankName: data.companyBankName,
            companyBankAccount: data.companyBankAccount,
            companySWIFT: data.companySWIFT,
            companyBIK: data.companyBIK,
            companyPhone: data.companyPhone,
            companyPassport: data.companyPassport,
            password: data.companyPassword,
            discount: data.discount,
            whoIsDealer: data.whoIsDealer,
            autDiscount: data.autDiscount,
            type: data.status
        }
        if (props.formType === 'add'){
            api.post('/reg.php', request).then((res) => {
                if (res.data === 'Готово') {
                    props.callPopup('Аккаунт успешно добавлен!', 'success')
                    reset()
                } else {
                    props.callPopup(`Аккаунт не был добавлен! Ошибка: ${res.data}`, 'error')
                }
            })
        } else {
            console.log('update req', request)
            api.post('/changeOrder.php', request).then((res) => {
                if (res.data === 'Готово') {
                    reset()
                    props.callPopup('Аккаунт успешно изменен!', 'success')
                    if (props.func) {
                        props.func()
                    }
                } else {
                    props.callPopup(`Аккаунт не был изменен! Ошибка: ${res.data}`, 'error')
                }
            })
        }
    }
    return(
        <form onSubmit={handleSubmit(onSubmit)}
              className="space-y-5">
            <React.Fragment>
                <div className="input-divs">
                    <label htmlFor="type">Тип заказчика:</label>
                    <select id="type"
                            {...register("type", {required: "Обязательное поле"})} defaultValue="">
                        <option />
                        <option>Юридическое лицо</option>
                        <option>Физическое лицо</option>
                    </select>
                </div>
                <div className="input-divs">
                    <label htmlFor="country">Страна:</label>
                    <select id="country"
                            {...register("country", {required: "Обязательное поле"})} defaultValue="">
                        <option />
                        <option>Россия</option>
                        <option>Казахстан</option>
                        <option>Беларусь</option>
                    </select>
                </div>
                <div className="input-divs">
                    <label htmlFor="status">Статус:</label>
                    <select id="status"
                            {...register("status", {required: "Обязательное поле"})}>
                        <option />
                        <option>Dealer</option>
                        <option>Subdealer</option>
                    </select>
                </div>
                <div className="input-divs">
                    <label htmlFor="regOrderNumber">Номер договора:</label>
                    <input type="text"
                           id="regOrderNumber"
                           {...register("regOrderNumber", {required: "Обязательное поле"})} />
                </div>
            </React.Fragment>
            {
                (watch().type || watch().country) && (
                    <React.Fragment>
                        <div className="input-divs">
                            {
                                (watch().type === 'Физическое лицо') && (
                                    <label htmlFor="companyName">ФИО:</label>
                                )
                            }
                            {
                                (watch().type === 'Юридическое лицо') && (
                                    <label htmlFor="companyName">Название фирмы:</label>
                                )
                            }
                            <input type="text"
                                   id="companyName"
                                   {...register("companyName", {required: "Обязательное поле"})} />
                        </div>
                        <div className="input-divs">
                            <label htmlFor="companyMail">E-mail:</label>
                            <input type="text"
                                   id="companyMail"
                                   readOnly = {mailStyle}
                                   {...register("companyMail", {required: "Обязательное поле"})} />
                        </div>
                        <div className="input-divs">
                            <label htmlFor="companyAdress">Адрес:</label>
                            <input type="text"
                                   id="companyAdress"
                                   {...register("companyAdress", {required: "Обязательное поле"})} />
                        </div>
                        {
                            (watch().type === 'Юридическое лицо' && watch().country === 'Беларусь') && (
                                <React.Fragment>
                                    <div className="input-divs">
                                        <label htmlFor="companyUNP">УНП:</label>
                                        <input type="text"
                                               id="companyUNP"
                                               {...register("companyUNP", {required: "Обязательное поле"})} />
                                    </div>
                                    <div className="input-divs">
                                        <label htmlFor="companySWIFT">SWIFT:</label>
                                        <input type="text"
                                               id="companySWIFT"
                                               {...register("companySWIFT", {required: "Обязательное поле"})} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                        {
                            (watch().type === 'Юридическое лицо' && (watch().country === 'Россия' || watch().country === 'Казахстан')) && (
                                <React.Fragment>
                                    <div className="input-divs">
                                        <label htmlFor="companyINN">ИНН:</label>
                                        <input type="text"
                                               id="companyINN"
                                               {...register("companyINN", {required: "Обязательное поле"})} />
                                    </div>
                                    <div className="input-divs">
                                        <label htmlFor="companyBIK">BIK:</label>
                                        <input type="text"
                                               id="companyBIK"
                                               {...register("companyBIK", {required: "Обязательное поле"})}/>
                                    </div>
                                    <div className="input-divs">
                                        <label htmlFor="companyKPP">{watch().country !== 'Казахстан' ? 'КПП:' : 'КБЕ:'}</label>
                                        <input type="text"
                                               id="companyKPP"
                                               {...register("companyKPP", {required: "Обязательное поле"})} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                        {
                            (watch().type === 'Юридическое лицо') && (
                                <React.Fragment>
                                    <div className="input-divs">
                                        <label htmlFor="companyBankName">Название Банка:</label>
                                        <input type="text"
                                               id="companyBankName"
                                               {...register("companyBankName", {required: "Обязательное поле"})} />
                                    </div>
                                    <div className="input-divs">
                                        <label htmlFor="companyBankAccount">Расчётный счет банка:</label>
                                        <input type="text"
                                               id="companyBankAccount"
                                               {...register("companyBankAccount", {required: "Обязательное поле"})} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                        {
                            (watch().type === 'Физическое лицо') && (
                                <React.Fragment>
                                    <div className="input-divs">
                                        <label htmlFor="companyPhone">Номер телефона:</label>
                                        <input type="text"
                                               id="companyPhone"
                                               {...register("companyPhone", {required: "Обязательное поле"})} />
                                    </div>
                                    <div className="input-divs">
                                        <label htmlFor="companyPassport">Номер паспорта:</label>
                                        <input type="text"
                                               id="companyPassport"
                                               {...register("companyPassport", {required: "Обязательное поле"})} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                        <div className="input-divs">
                            <label htmlFor="companyPassword">Пароль:</label>
                            <input type="text"
                                   id="companyPassword"
                                   {...register("companyPassword", {required: "Обязательное поле"})} />
                        </div>
                        {
                            (watch().status === 'Subdealer') && (
                                <React.Fragment>
                                    <div className="input-divs">
                                        <label htmlFor="whoIsDealer">Email диллера:</label>
                                        <input type="text"
                                               id="whoIsDealer"
                                               {...register("whoIsDealer", {required: "Обязательное поле"})} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                        <div className="input-divs">
                            <label htmlFor="discount">Скидка на изделие:</label>
                            <input type="text"
                                   id="discount"
                                   {...register("discount", {required: "Обязательное поле"})} />
                        </div>
                        <div className="input-divs">
                            <label htmlFor="autDiscount">Скидка на автоматику:</label>
                            <input type="text"
                                   id="autDiscount"
                                   {...register("autDiscount", {required: "Обязательное поле"})} />
                        </div>
                    </React.Fragment>
                )
            }
            <div className="flex justify-center space-x-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">Записать</button>
            </div>
        </form>
    )
}

export default AdminAddForm