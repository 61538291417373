import axios from "axios";
import {LoginData, prevUserData, UserData} from "../types/types";

const api = axios.create({
    baseURL: 'https://wholesale.markiz.by/'
})
//Fetch information form database about a user using provided data
const UserService = async ({email, password}: LoginData) => {

    const res = await api.post('/get.php', {email,password})
    const data: prevUserData = res.data
    if (res.data.error == null) {
        return {
            name: data.companyName,
            type: data.companyType,
            country: data.companyContry,
            address: data.companyAdress,
            mail: data.companyMail,
            unp: data.companyUNP,
            inn: data.companyINN,
            kpp: data.companyKPP,
            bankName: data.companyBankName,
            bankAccount: data.companyBankAccount,
            swift: data.companySWIFT,
            bik: data.companyBIK,
            phone: data.companyPhone,
            passport: data.companyPassport,
            orderNumber: data.orderNumber,
            role: data.type,
            dealerDiscounts: data.dealerDiscounts,
            discount: data.discount,
            autDiscount: data.autDiscount,
            whoIsDealer: data.whoIsDealer
        }
    } else {
        return 'Error'
    }
}

export default UserService