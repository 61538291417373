import React from "react";
import axios from "axios";
import {useForm} from "react-hook-form";

type UpdateDateFormValues = {
    orderNumber: string,
    date: string
}
type props = {
    callPopup: (message: string, type: string) => void
}

const AdminDateUpdateForm = ({callPopup}: props) => {

    const { register, handleSubmit } = useForm<UpdateDateFormValues>({
        shouldUnregister: true
    })

    const api = axios.create({
        baseURL: 'https://wholesale.markiz.by/'
    })

    const onSubmit = (data: UpdateDateFormValues) => {
        const request = {
            orderNumber: data.orderNumber,
            date: data.date
        }
        console.log(request)
        api.post('/changeOrderReadyDate.php', request).then((res) => {
            if (res.data === 'Готово') {
                callPopup('Дата успешно изменена!', 'success')
            } else {
                callPopup(`${res.data}`, 'error')
            }
        })
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-divs">
                <label htmlFor="orderNumber">Впишите номер заказа:</label>
                <input type="text" id="orderNumber" {...register("orderNumber", {required: "Обязательное поле"})} />
                <br/>
                <label htmlFor="date">Выберите новую дату:</label>
                <input type='date' id="date" {...register("date", {required: "Обязательное поле"})} />
            </div>
            <div className="flex justify-center space-x-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">Сохранить</button>
            </div>
        </form>
    )
}

export default AdminDateUpdateForm